<template>
  <b-overlay
    variant="white"
    :show="isLoadingData"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Kode Voucher"
                rules="required"
              >
                <b-form-group label="Kode Voucher" label-for="discount-code">
                  <b-form-input
                    id="discount-code"
                    v-model="form.code"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Kode Voucher"
                    :readonly="!canEdit"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Jenis"
                rules="required"
              >
                <b-form-group label="Jenis" label-for="type">
                  <b-form-select
                    id="type"
                    v-model="form.type"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Jenis Diskon"
                    :readonly="!canEdit"
                    :options="typeOptions"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Value"
                rules="required"
              >
                <b-form-group label="Value" label-for="value">
                  <b-form-input
                    id="value"
                    v-model="form.value"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Nilai Diskon"
                    :readonly="!canEdit"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Deskripsi"
                rules=""
              >
                <b-form-group label="Deskripsi" label-for="description">
                  <b-form-input
                    id="description"
                    v-model="form.description"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Deskripsi"
                    :readonly="!canEdit"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Minimal Transaksi"
                rules=""
              >
                <b-form-group
                  label="Minimal Transaksi"
                  label-for="minimal_transaction_value"
                >
                  <b-form-input
                    id="minimal_transaction_value"
                    v-model="form.min_transaction_value"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Minimal Transaksi"
                    :readonly="!canEdit"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Mulai Berlaku"
                rules=""
              >
                <b-form-group label="Mulai Berlaku" label-for="start_date">
                  <b-form-datepicker
                    id="start_date"
                    v-model="form.start_date"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Tanggal Mulai Berlaku"
                    :readonly="!canEdit"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Tanggal Expired"
                rules=""
              >
                <b-form-group label="Tanggal Expired" label-for="expire_date">
                  <b-form-datepicker
                    id="expired_date"
                    v-model="form.expire_date"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Tanggal Expired Voucher"
                    :readonly="!canEdit"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Jumlah Redem"
                rules=""
              >
                <b-form-group
                  label="Jumlah Redem"
                  label-for="limit_number_redemptions"
                >
                  <b-form-input
                    id="limit_number_redemptions"
                    v-model="form.limit_number_redemptions"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Jumlah Redem"
                    :readonly="!canEdit"
                    type="number"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="White List Product"
                rules=""
              >
                <b-form-group
                  label="White List Product"
                  label-for="white_list_product"
                >
                  <v-select
                    id="white_list_product"
                    v-model="whiteListProduct"
                    label="kd_produk"
                    multiple
                    :filterable="true"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="White List Product"
                    :readonly="!canEdit"
                    :options="productOptions"
                    @search="onSearch"
                  >
                    <template #no-options="{ search, searching, loading }">
                      {{
                        search.length === 0
                          ? 'Type To Search.....'
                          : 'No Options Data'
                      }}
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ `${option.kd_produk} - ${option.nama_produk}` }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ `${option.kd_produk}` }}
                      </div>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Black List Product"
                rules=""
              >
                <b-form-group
                  label="Black List Product"
                  label-for="black_list_product"
                >
                  <v-select
                    id="black_list_product"
                    v-model="blackListProduct"
                    label="kd_produk"
                    multiple
                    :filterable="true"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Black List Product"
                    :readonly="!canEdit"
                    :options="productOptions"
                    @search="onSearch"
                  >
                    <template #no-options="{ search, searching, loading }">
                      {{
                        search.length === 0
                          ? 'Type To Search.....'
                          : 'No Options Data'
                      }}
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ `${option.kd_produk} - ${option.nama_produk}` }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ `${option.kd_produk}` }}
                      </div>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Term And Condition"
                rules="required"
              >
                <b-form-group label="Term And Condition" label-for="tnc">
                  <cke-editor v-model="form.tnc" style="height: 150px" />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <span>Submit</span>
              <b-spinner v-if="isLoading" small class="ml-1" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import 'vue-toastification/dist/index.css'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BFormSelect,
  BFormDatepicker,
  BSpinner,
  BCard,
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, {
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { typeVoucherOptions } from '@core/utils/constant'
import store from '@/store'
import CkeEditor from '@/components/cke-editor/CkeEditor.vue'
import service from '@/components/Table/service'
import discountStoreModule from './discountStoreModule'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BFormDatepicker,
    BButton,
    vSelect,
    BSpinner,
    BCard,
    BCol,
    BRow,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    CkeEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      productOptions: [],
    }
  },
  methods: {
    onSearch(search, loading) {
      this.filter(search, loading, this)
    },
    filter: _.debounce((search, loading, vm) => {
      loading(true)
      store
        .dispatch('vouchers/productOnSearch', { search })
        .then(res => {
          vm.productOptions = res.data.data
        })
        .finally(() => {
          loading(false)
        })
    }, 1000),
  },
  setup(props, { emit, root }) {
    const DISCOUNT_STORE_MODULE = 'vouchers'
    if (!store.hasModule(DISCOUNT_STORE_MODULE)) {
      store.registerModule(DISCOUNT_STORE_MODULE, discountStoreModule)
    }
    onUnmounted(() => {
      store.unregisterModule(DISCOUNT_STORE_MODULE)
    })

    const whiteListProduct = ref([])
    const blackListProduct = ref([])
    const blankFormData = {
      type: null,
      code: null,
      value: null,
      description: null,
      min_transaction_value: null,
      start_date: null,
      expire_date: null,
      limit_number_redemptions: null,
      black_list_product: null,
      white_list_product: null,
      tnc: '',
    }

    const typeOptions = ref([
      {
        text: 'Pilih Jenis Diskon',
        value: null,
      },
      ...typeVoucherOptions,
    ])

    const canEdit = ref(true)

    const form = ref(JSON.parse(JSON.stringify(blankFormData)))
    const resetpageData = () => {
      form.value = JSON.parse(JSON.stringify(blankFormData))
    }

    const isLoading = ref(false)

    watch(
      () => props.isAddNewPageSidebarActive,
      (newValue, prevValue) => {
        if (props.typeForm === 'edit') {
          canEdit.value = true
          form.value = props.data
        } else if (props.typeForm === 'show') {
          canEdit.value = false
          form.value = props.data
        } else {
          canEdit.value = true
          form.value = JSON.parse(JSON.stringify(blankFormData))
        }
      }
    )

    const retrieve = () => {      
      isLoadingData.value = true
      service
          .show({ url: 'vouchers', id: root.$route.params.id })
          .then(res => {
            form.value = res.data
            blackListProduct.value = res.data.conditions.find(
              e => e.operator === 'NOT IN'
            ).contents
            whiteListProduct.value = res.data.conditions.find(
              e => e.operator === 'IN'
            ).contents
          })
          .finally(() => {
            isLoadingData.value = false
          })
    }

    const onSubmit = () => {
      isLoading.value = true
      form.value.white_list_product = whiteListProduct.value
        .map(e => e.kd_produk)
        .join(',')
      form.value.black_list_product = blackListProduct.value
        .map(e => e.kd_produk)
        .join(',')
      if (root.$route.params.id) {
        form.value.id = root.$route.params.id
        service
          .update({ url: 'vouchers', data: form.value })
          .then(res => {
            retrieve()
          })
          .catch(err => {
            const element = Vue.component('description', {
              render: h => serializeErrorMessage(h, err),
            })
            const toast = createToastInterface({
              timeout: 6000,
            })
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error Add Voucher',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                description: element,
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        service
          .store({ url: 'vouchers', data: form.value })
          .then(res => {
            root.$router.go(-1)
          })
          .catch(err => {
            const element = Vue.component('description', {
              render: h => serializeErrorMessage(h, err),
            })
            const toast = createToastInterface({
              timeout: 6000,
            })
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error Edit Voucher',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                description: element,
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData)

    const isLoadingData = ref(false)

    onMounted(() => {
      if (root.$route.params.id) {
        retrieve()
      }
    })

    return {
      tglLahir: null,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      canEdit,
      props,
      typeOptions,
      isLoading,
      whiteListProduct,
      blackListProduct,
      isLoadingData,

      form,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
